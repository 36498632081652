import { ABILITIES, AUTH_NAMESPACE, USER } from "store/modules/auth";
const mixin = {
  computed: {
    /**
    * Retorna o usuário logado salvo no $store
    */
    $user() {
      return {
        ...this.$store.getters[`${AUTH_NAMESPACE}/${USER}`],
        permissions: this.$store.getters[`${AUTH_NAMESPACE}/${ABILITIES}`]
      }
    },
    /**
    * Retorna a propriedade padrão usada em todas as tabelas
    */
    $footerProps() {
      return {
        'items-per-page-options': [5, 10, 15, 25, 50, -1],
      };
    },
    /**
    * Retorna os tipos de condição usados no AVP
    */
    $tipoCondicaoOptions() {
      return [
        { descricao: 'Discente', value: 'discente' },
        { descricao: 'Docente', value: 'docente' },
      ]
    },
    /**
    * Retorna a data/hora de hoje formatada
    */
    $hoje() {
      return new Date().toLocaleDateString()
    },
    $buildDev() {
      return this.$configurations.env.nodeEnv == "development"
    },
    $recaptchaDev() {
      return this.$configurations.env.recaptcha;
    }
  },
  methods: {
    /**
    * Redireciona para a página home
    * @param app Opcional - path home a ser redirecionado
    */
    $toHome(app = "/app") {
      this.$router.push({ path: app });
    },
    /**
    * Retorna uma página no histórico
    */
    $back() {
      this.$router.go(-1);
    },
    /**
    * Reseta o form padrão ref="form" e o captcha caso exista
    * @param form Opcional - Referência da tag form em tela
    */
    $resetForm(form) {
      form ? form.reset() : this.$refs.form.reset();
      this.$refs.recaptcha && this.$refs.recaptcha.onCaptchaExpired();
    },
    /**
    * Valida o formulário mostrando a mensagem caso inválido
    * @param msg Opcional - Mensagem de campos obrigatórios
    */
    $validarFormulario(msg = "Preencha todos os campos obrigatórios!") {
      return this.$refs.form.validate()
        ? true
        : this.$notifyWarning(msg)
    },
    /**
    * Converte um base64 em um new File()
    * @param base64 String de base64
    * @param filename Nome do arquivo gerado
    * @param mimeType Formato do arquivo
    */
    $convertBase64ToFile(base64, filename, mimeType) {
      return (fetch(`data:${mimeType};base64,${base64}`)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }))
      );
    },
    /**
    * Formata o CPF no formato padrão '###.###.###-##'
    * @param value Valor do CPF a ser formatado
    */
    $formatCpf(cpf) {
      return (cpf || '').replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },
    /**
    * Verifica se o usuário logado possui alguma permissão de "administrador."
    */
    $isUserAdmin() {
      return this.$user && this.$user.permissions.some((g) => g.includes('administrador.'));
    },
    /**
    * Retira a máscara do CPF '###.###.###-##' e retorna '###########'
    * @param value Valor do CPF mascarado
    */
    $unMask(value) {
      if (!value) return "";
      return value.split(".").join("").split("-").join("");
    },
    /**
    * Ignora caso o usuário não tenha digitado um número no input
    * usar em conjunto do @keypress="$event"
    * @param evt Evento recebido pelo input
    */
    $isNumber(evt) {
      if (!["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(evt.key)) {
        evt.preventDefault();
      }
    },
    /**
    * Rola a página até o topo
    */
    $scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    /**
    * Formata o date em formato americano para enviar para a api
    * @param data String da data a ser enviada para o back
    * @param hora Opcional - String da Hora em formato 'hh:mm:ss'
    */
    $formatarDateParaEnviar(data, hora = "00:00:00") {
      if (data && data.includes("/")) {
        const [dia, mes, ano] = data.split("/");
        return `${ano}-${mes}-${dia}T${hora}`;
      } else {
        return `${data}T${hora}`;
      }
    },
    /**
    * Esconde o CPF em tela adicionando # no formato '###.###.000-##'
    * @param value Valor do CPF a ser mascarado
    */
    $esconderCpf(value) {
      return this.$formatCpf(value)
        .split("")
        .map((v, i) => {
          return [8, 9, 10].includes(i) || v.includes(".") || v.includes("-")
            ? v
            : "#";
        })
        .toString()
        .split(",")
        .join("");
    },
    /**
    * Monta os params corretamente enviando null no lugar de 'Todos'.
    * @param filters Objeto contendo os filtros informados em tela Ex: this.form.filters
    */
    $montarParamsFilters(filters) {
      return {
        params: {
          ...filters,
          situacao: filters.situacao == "Todas" ? null : filters.situacao,
          ano: filters.ano == "Todos" ? null : filters.ano,
          semestre: filters.semestre == "Todos" ? null : filters.semestre
        }
      }
    },
    /**
    * Cria um elemento CSV na tela e realiza o download no navegador do usuário.
    * @param bytes Array de bytes retornado pelo back-end
    * @param nomeDoRelatorio Nome do relatório (csv) a ser baixado
    */
    $baixarRelatorioCSV(bytes, nomeDoRelatorio = "relatorio") {
      const getEncodeData = () => encodeURIComponent(`\uFEFF${bytes}`);
      const a = window.document.createElement("a");
      a.setAttribute("href", `data:text/csv; charset=utf-8,${getEncodeData()}`);
      a.setAttribute("download", `${nomeDoRelatorio}-${Date.now()}.csv`);
      a.click();
      this.$notifySuccess("Relatório baixado com sucesso!");
    },
    $base64ToArrayBuffer(data) {
      var bString = window.atob(data);
      var bLength = bString.length;
      var bytes = new Uint8Array(bLength);
      for (var i = 0; i < bLength; i++) {
          var ascii = bString.charCodeAt(i);
          bytes[i] = ascii;
      }
      return bytes;
    },

    $getFileFromBase64(base64, fileName, fileType, extension) {
        const bufferArray = this.$base64ToArrayBuffer(base64);
        const blob = new Blob([bufferArray], { type: fileType });
        return new File([blob], `${fileName}.${extension}`)
    },
    $baixarArquivo(file, fileType) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        const blob = new Blob([file], { type: fileType });
        const objectURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.$notifySuccess("Relatório baixado com sucesso!");
      }
    
  },
};
export default mixin;